import { callApiUsingFunction } from './fetchFunction';
export const onMessageListeners = [];

function onMessage(callback) {
	onMessageListeners.push(callback);
}

function createRequest({ method }) {
	return async function request({ path, headers, json, body, callback, query }) {
		let qs = '';
		const params = [];

		for (let key in json) {
			if (json[key] === '') {
				delete json[key];
			}
		}
		for (let key in query) {
			if (query[key] === '') {
				delete query[key];
			}
		}

		if (query) {
			qs = '?';
			for (const queryParam in query) {
				params.push(`${queryParam}=${query[queryParam]}`);
			}
			qs += params.join('&');
		}

		return new Promise(async (resolve, reject) => {
			let notification;
			let response;
			let errorHandling;
			try {
				response = await callApiUsingFunction({
					callUrl: `${path}${qs}`,
					method,
					headers: headers,
					json,
					body
				});
				notification = response.data?.message
					? response?.data?.message || undefined
					: response.data?.msg
					? response.data?.msg || undefined
					: undefined;
			} catch (error) {
				if (error.response) {
					const handleError = error.response?.data;
					if (handleError) {
						if (Array.isArray(handleError?.error)) {
							notification = handleError?.error[0]?.msg || undefined;
						} else {
							notification = handleError?.message || undefined;
						}
					} else {
						notification =
							error?.response?.message ||
							error?.response?.data?.message ||
							'An error occurred, please try again';
					}
				} else {
					/**@@ That means might be the error from the request or junt an error happened */
					/** error.request is an instance of XMLHttpRequest in the browser and an instance of
					 http.ClientRequest in node.js */
					/**
					 * If not error.request, that means somthing happened in setting up the request that triggered an 'Error'
					 */
					notification = 'An error occurred, please try again';
				}

				errorHandling = error;
			} finally {
				onMessageListeners.forEach(callback => {
					if (notification && typeof notification === 'string') {
						callback(`${notification}`);
					}
				});

				if (response && response?.status && response?.status < 400) {
					return resolve(response);
				} else {
					return reject(errorHandling);
				}
			}
		});
	};
}

export const api = {
	v1: {
		post: createRequest({ method: 'post' }),
		get: createRequest({ method: 'get' }),
		put: createRequest({ method: 'put' }),
		delete: createRequest({ method: 'delete' }),
		patch: createRequest({ method: 'patch' })
	}
};

const logout = () => {};
const getOrdersApi = filter => {
	return api.v1.get({ path: '/orders', query: filter });
};

const getOrderByIdApi = id => {
	return api.v1.get({ path: `/orders/${id}` });
};

const updateShippingAddressApi = (data, orderId) => {
	return api.v1.patch({ path: `/orders/${orderId}/shippingAddress`, json: data });
};

const reorderApi = orderId => {
	return api.v1.post({ path: `/orders/${orderId}/reorder` });
};

const cancelOrderApi = (data, orderId) => {
	return api.v1.patch({ path: `/orders/${orderId}/status/canceled`, json: data });
};
const checkBoxesSubmitApi = (orderIds, status) => {
	return api.v1.patch({ path: `/orders/bulk/status/${status}`, json: orderIds });
};

const getAllCountriesFromServer = () => {
	return api.v1.get({ path: `/country` });
};

const getCourierListApi = () => {
	return api.v1.get({ path: '/courier' });
};

const updateOrderDetialsApi = (data, orderId) => {
	return api.v1.patch({ path: `/orders/${orderId}`, json: data });
};
const expediteOrderApi = (orderId, flag) => {
	return api.v1.patch({ path: `/orders/${orderId}/isExpedited/${flag}` });
};
const setExpeditedShippingDateApi = (orderId, data) => {
	return api.v1.patch({ path: `/orders/${orderId}/ExpeditedShippingDate`, json: data });
};
export {
	onMessage,
	logout,
	getOrdersApi,
	getOrderByIdApi,
	updateShippingAddressApi,
	reorderApi,
	cancelOrderApi,
	checkBoxesSubmitApi,
	getAllCountriesFromServer,
	getCourierListApi,
	updateOrderDetialsApi,
	expediteOrderApi,
	setExpeditedShippingDateApi
};
